define("ember-svg-jar/utils/make-svg", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.formatAttrs = formatAttrs;
  _exports.symbolUseFor = symbolUseFor;
  _exports.inlineSvgFor = inlineSvgFor;
  _exports.default = makeSvg;

  function formatAttrs(attrs) {
    return Object.keys(attrs).map(key => !Ember.isNone(attrs[key]) && "".concat(key, "=\"").concat(attrs[key], "\"")).filter(attr => attr).join(' ');
  }

  function symbolUseFor(assetId, attrs = {}) {
    return "<svg ".concat(formatAttrs(attrs), "><use xlink:href=\"").concat(assetId, "\" /></svg>");
  }

  function inlineSvgFor(assetId, getInlineAsset, attrs = {}) {
    let asset = getInlineAsset(assetId);

    if (!asset) {
      // eslint-disable-next-line no-console
      console.warn("ember-svg-jar: Missing inline SVG for ".concat(assetId));
      return;
    }

    let svgAttrs = asset.attrs ? Ember.assign({}, asset.attrs, attrs) : attrs;
    let {
      size
    } = attrs;

    if (size) {
      svgAttrs.width = parseFloat(svgAttrs.width) * size || svgAttrs.width;
      svgAttrs.height = parseFloat(svgAttrs.height) * size || svgAttrs.height;
      delete svgAttrs.size;
    }

    return "<svg ".concat(formatAttrs(svgAttrs), ">").concat(asset.content, "</svg>");
  }

  function makeSvg(assetId, attrs = {}, getInlineAsset) {
    if (!assetId) {
      // eslint-disable-next-line no-console
      console.warn('ember-svg-jar: asset name should not be undefined or null');
      return;
    }

    let isSymbol = assetId.lastIndexOf('#', 0) === 0;
    let svg = isSymbol ? symbolUseFor(assetId, attrs) : inlineSvgFor(assetId, getInlineAsset, attrs);
    return Ember.String.htmlSafe(svg);
  }
});