define("ember-svg-jar/inlined/smile", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<title>smile</title><g fill=\"none\" fill-rule=\"evenodd\"><path d=\"M750 66c272.408 0 525.477-24.727 750-66H0c223.807 40.93 478.805 66 750 66z\" fill=\"#000\"/><path d=\"M750 40c271.733 0 525.875-14.977 750-40H0c223.4 24.813 479.491 40 750 40z\" fill=\"#191919\"/></g>",
    "attrs": {
      "width": "1500",
      "height": "66",
      "viewBox": "0 0 1500 66",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});